import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(main)": [~30,[4]],
		"/(main)/__helpers/clear": [31,[4]],
		"/(main)/__helpers/form": [32,[4]],
		"/(main)/blog": [~33,[4]],
		"/(main)/blog/[post]/[[title]]": [~34,[4]],
		"/(main)/category/[id]/[[name]]": [~35,[4]],
		"/(dashboard)/dashboard": [5,[2,3]],
		"/(dashboard)/dashboard/admin": [~6,[2,3]],
		"/(dashboard)/dashboard/admin/clear-accounts": [~7,[2,3]],
		"/(dashboard)/dashboard/admin/emails": [8,[2,3]],
		"/(dashboard)/dashboard/admin/init-custom-flavors": [~9,[2,3]],
		"/(dashboard)/dashboard/admin/playground": [~10,[2,3]],
		"/(dashboard)/dashboard/admin/reflect": [~11,[2,3]],
		"/(dashboard)/dashboard/admin/refresh-config": [~12,[2,3]],
		"/(dashboard)/dashboard/admin/spreadsheet": [~13,[2,3]],
		"/(dashboard)/dashboard/admin/telegram": [14,[2,3]],
		"/(dashboard)/dashboard/analytics/requested-flavors": [~15,[2,3]],
		"/(dashboard)/dashboard/analytics/search": [~16,[2,3]],
		"/(dashboard)/dashboard/blog": [~17,[2,3]],
		"/(dashboard)/dashboard/blog/post/[postid]": [~18,[2,3]],
		"/(dashboard)/dashboard/inquiries": [~19,[2,3]],
		"/(dashboard)/dashboard/inquiries/[...id]": [~20,[2,3]],
		"/(dashboard)/dashboard/inquiry-stats": [~21,[2,3]],
		"/(dashboard)/dashboard/newsletter": [~22,[2,3]],
		"/(dashboard)/dashboard/not-allowed": [23,[2,3]],
		"/(dashboard)/dashboard/orders": [~24,[2,3]],
		"/(dashboard)/dashboard/orders/[...id]": [~25,[2,3]],
		"/(dashboard)/dashboard/products": [~26,[2,3]],
		"/(dashboard)/dashboard/products/[...id]": [~27,[2,3]],
		"/(main)/inquiry": [36,[4]],
		"/(main)/inquiry/sent": [37,[4]],
		"/(main)/legal/privacy-policy": [38,[4]],
		"/(links)/links/invoice/[...id]": [~29],
		"/(main)/login2/[...expected]": [~39,[4]],
		"/(main)/order": [~40,[4]],
		"/(main)/product/[id]/[[name]]": [~41,[4]],
		"/(main)/register/thank-you": [42,[4]],
		"/(main)/search": [43,[4]],
		"/(main)/ui2": [44,[4]],
		"/(dashboard)/ui": [~28,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';